<template>
  <section class="official--partner-header">
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="section--gallery">
          <client-only>
            <vue-gallery-slideshow
              :images="galleryImages"
              :index="imageIndex"
              @close="imageIndex = null"
            ></vue-gallery-slideshow>
          </client-only>
          <v-tabs>
            <v-tab
              :href="`#tab-1`"
              v-if="partnerDetail.photos && partnerDetail.photos.length > 0"
              >{{ $t(`general.gallery`) }}</v-tab
            >

            <v-tab-item
              :value="'tab-1'"
              v-if="partnerDetail.photos && partnerDetail.photos.length > 0"
            >
              <div @click="showImage">
                <img
                  class="display--photos"
                  :src="partnerDetail.photos[0]"
                  :alt="`${partnerDetail.name} - Rentfix`"
                />
                <button class="btn btn-primary--outline open--gallery" @click="showImage">
                  <i class="ion-images"></i>
                  <span v-if="partnerDetail.photos !== null"
                    >{{ $t('general.view1') }} {{ partnerDetail.photos.length }}
                    {{ $t('general.view2') }}</span
                  >
                </button>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <ActionSection
          :partner_image="partnerDetail.logo_photo"
          :partner_web="null"
          :name="partnerDetail.name"
          :description="partnerDetail.description"
        />
      </div>
    </div>
  </section>
</template>

<script>
const VueGallerySlideshow = () => import('vue-gallery-slideshow');
const ActionSection = () => import('@/components/official-partner/action');
export default {
  props: ['partnerDetail'],
  components: {
    VueGallerySlideshow,
    ActionSection,
  },
  data: () => ({
    imageIndex: null,
  }),
  computed: {
    galleryImages() {
      let images = [];
      for (let photo of this.partnerDetail.photos) {
        images.push({
          url: photo,
          alt: this.partnerDetail.name + ' - Rentfix',
        });
      }
      return images;
    },
  },
  methods: {
    showImage() {
      this.imageIndex = 0;
    },
  },
};
</script>
